import './skip-link-focus-fix';
import 'script-loader!slick-carousel';
import 'script-loader!slick-lightbox';
import 'script-loader!lity';

(function($) {

	$(document).ready(function() {
		menuToggle();
		fullWidthVideo();
		faqsBlock();
		accordionShortcode();
		tabContent();
		testimonialReadMore();
		galleryLightbox();
		repeaterGalleryLightbox();

		$('a[href*=#]').on('click', function(e){
			e.preventDefault();

			$('html, body').animate({
				scrollTop: $( $.attr(this, 'href') ).offset().top - $('#masthead').outerHeight()
			}, 500);
		});
	});

	// Header menu toggle
	function menuToggle() {
		$("#menu-toggle").on('click', function() {
			$(this).toggleClass('site-header__menu-toggle--open');
			$("#menu-primary-menu").toggleClass('site-header__menu--open');
		});

		$(".submenu-toggle").on('click', function() {
			var submenu = $(this).next('.sub-menu');
			$(this).toggleClass('submenu-toggle--open');
			submenu.slideToggle();
		});
	}

	// Full width video toggle
	function fullWidthVideo() {
		$('.full-image__overlay').on('click', function(e) {
			e.preventDefault();

			var videoWrap = $(this).next('.full-image__video'),
			    video     = videoWrap.find('iframe');

			videoWrap.show();
			video[0].src += "&autoplay=1";
		});
	}

	// FAQs block
	function faqsBlock() {
		$(".faqs-block__title").on('click', function(e) {
			e.preventDefault();

			var faq     = $(this).closest('.faqs-block__faq'),
			    content = faq.find('.faqs-block__content');
				
			content.slideToggle(300);
			faq.toggleClass('faqs-block__faq--open');

			$('.faqs-block__content').not(content).slideUp(300);
			$('.faqs-block__faq').not(faq).removeClass('faqs-block__faq--open');
		});
	}

	// Accordion shortcode
	function accordionShortcode() {
		$('.accordion-shortcode__toggle').on('click', function () {
			var accordion = $(this).parent('.accordion-shortcode'),
				content = $(this).prev('.accordion-shortcode__content');

			content.slideToggle();
			accordion.toggleClass('accordion-shortcode--open');

			if ($(this).text() == 'Close') {
				var title = $(this).data('title');
				$(this).text(title);
			} else {
				$(this).text('Close');
			}
		});
	}

	// Tab content block
	function tabContent() {
		$('.tab-content__tab').on('click', function (e) {
			e.preventDefault();

			var id          = $(this).data('tab'),
			    block       = $(this).closest('.tab-content'),
			    tabs        = $(this).siblings(),
			    content     = block.find('.tab-content__content'),
			    thisContent = block.find('.tab-content__content[data-tab="' + id + '"]');

			content.hide();
			thisContent.show();

			tabs.removeClass('tab-content__tab--active');
			$(this).addClass('tab-content__tab--active');
		});
	}

	// Testimonial read more accordion
	function testimonialReadMore() {
		$('.testimonials-block__read-more').on('click', function (e) {
			e.preventDefault();

			var fullContent = $(this).siblings('.testimonials-block__full-content'),
			    excerpt     = $(this).siblings('.testimonials-block__excerpt');

			excerpt.slideToggle();
			fullContent.slideToggle();

			if ($(this).text() == 'Read Less') {
				$(this).text('Read More');
			} else {
				$(this).text('Read Less');
			}
		});
	}

	// Gallery lightbox
	function galleryLightbox() {
		var gallery = $('.gallery-block__wrap');
		
		gallery.each(function(){
			$(this).slickLightbox({
				itemSelector: '> a',
				caption     : 'caption'
			});
		});
	}

	// Repeater gallery lightbox
	function repeaterGalleryLightbox() {
		var gallery = $('.repeater-gallery__wrap');
		
		gallery.each(function(){
			$(this).slickLightbox({
				itemSelector: '> a',
				caption     : 'caption'
			});
		});
	}

	/**
	 * initMap
	 *
	 * Renders a Google Map onto the selected jQuery element
	 *
	 * @date    22/10/19
	 * @since   5.8.6
	 *
	 * @param   jQuery $el The jQuery element.
	 * @return  object The map instance.
	 */
	function initMap( $el ) {

		// Find marker elements within map.
		var $markers = $el.find('.marker');

		// Create gerenic map.
		var mapArgs = {
			zoom        : $el.data('zoom') || 16,
			mapTypeId   : google.maps.MapTypeId.ROADMAP
		};
		var map = new google.maps.Map( $el[0], mapArgs );

		// Add markers.
		map.markers = [];
		$markers.each(function(){
			initMarker( $(this), map );
		});

		// Center map based on markers.
		centerMap( map );

		// Return map instance.
		return map;
	}

	/**
	 * initMarker
	 *
	 * Creates a marker for the given jQuery element and map.
	 *
	 * @date    22/10/19
	 * @since   5.8.6
	 *
	 * @param   jQuery $el The jQuery element.
	 * @param   object The map instance.
	 * @return  object The marker instance.
	 */
	function initMarker( $marker, map ) {

		// Get position from marker.
		var lat = $marker.data('lat');
		var lng = $marker.data('lng');
		var latLng = {
			lat: parseFloat( lat ),
			lng: parseFloat( lng )
		};

		// Create marker instance.
		var marker = new google.maps.Marker({
			position : latLng,
			map: map
		});

		// Append to reference for later use.
		map.markers.push( marker );

		// If marker contains HTML, add it to an infoWindow.
		if( $marker.html() ){

			// Create info window.
			var infowindow = new google.maps.InfoWindow({
				content: $marker.html()
			});

			// Show info window when marker is clicked.
			google.maps.event.addListener(marker, 'click', function() {
				infowindow.open( map, marker );
			});
		}
	}

	/**
	 * centerMap
	 *
	 * Centers the map showing all markers in view.
	 *
	 * @date    22/10/19
	 * @since   5.8.6
	 *
	 * @param   object The map instance.
	 * @return  void
	 */
	function centerMap( map ) {

		// Create map boundaries from all map markers.
		var bounds = new google.maps.LatLngBounds();
		map.markers.forEach(function( marker ){
			bounds.extend({
				lat: marker.position.lat(),
				lng: marker.position.lng()
			});
		});

		// Case: Single marker.
		if( map.markers.length == 1 ){
			map.setCenter( bounds.getCenter() );

		// Case: Multiple markers.
		} else{
			map.fitBounds( bounds );
		}
	}

	// Render maps on page load.
	$(document).ready(function(){
		$('.fifty-map__map').each(function(){
			var map = initMap( $(this) );
		});
	});

})(jQuery);